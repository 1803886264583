.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}

.menu {
  width: 100%;
  padding: 30px 50px;
  background: #f5f8ff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.timezone,
.makeup,
.time,
.network,
.level {
  width: 185px;
  height: 38px;
}

.timezone {
  width: 145px;
}

.inputWrapper {
  margin-left: 20px;
  color: hsl(0, 0%, 20%);
  display: flex;
}

.checkboxWrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.checkbox {
  user-select: none;
  cursor: pointer;
}

.input {
  display: flex;
  color: hsl(0, 0%, 20%);
  font-size: 100%;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  outline: none;
  min-height: 38px;
  box-sizing: border-box;
  transition: all 100ms;
  padding: 2px 8px;
  max-width: 90px;

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0 1px #2684ff;
    border-color: #2684ff;
  }
}

.inputTime {
  width: 40px;
}

.button {
  background: #4a72ff;
  border-radius: 10px;
  font-weight: 700;
  height: 38px;
  width: 145px;
}

.starts {
  display: flex;
}

.profileImage {
  display: block;
  width: 32px;
  height: 32px;
}

.alias {
  display: flex;
  font-size: 18px;
}

.dot {
  width: 6px;
  height: 6px;
  background-color: #4a72ff;
  border-radius: 50%;
}

.info,
.alias,
.userInfo {
  display: flex;
  align-items: center;
  gap: 15px;
}

.info {
  margin-top: 10px;
  padding: 0 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.settings {
  cursor: pointer;
  font-weight: 700;
  color: #4a72ff;

  &:hover {
    color: #3157de;
  }
}

.prizepool {
  margin-top: 20px;
}

.line {
  width: 2px;
  background-color: #4a72ff;
  height: 26px;
  border-radius: 20px;
}
