@import "src/assets/style/design.scss";

.BaseInputString {
  box-sizing: border-box;

  width: 100%;
  height: 48px;
  padding-left: 10px;

  border: none;
  border-radius: 10px;
  outline: none;
  box-shadow: $shadow100;

  background-color: #f5f8ff;

  transition: 50ms ease-out box-shadow;

  cursor: text;

  &:focus,
  &:active {
    outline: none;
    box-shadow: $shadow300;
  }

  &::placeholder {
    color: rgb(160, 160, 160);
  }

  &:disabled {
    cursor: default;

    background-color: rgb(242, 242, 242);
    color: rgb(132, 132, 132);

    box-shadow: $shadow100;
  }
}
