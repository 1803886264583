.password-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;


  &__content-wrapper {
    width: 800px;
  }

  &__input-wrapper {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__label {
    display: block;

    margin-bottom: 8px;

    font-size: 23px;
  }

  &__submit-button {
    margin-top: 20px;
    padding: 16px 24px;
  }
}
