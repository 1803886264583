$baseColor: #4a72ff;

.percent-section {
  color: $baseColor;
  font-size: 20px;

  &__wrapper {
    display: flex;
    height: 40px;
  }

  &__title {
    margin-bottom: 20px;

    font-size: 1.2em;
  }

  &__input,
  &__button {
    font-size: 0.8em;
    height: 40px;
  }

  &__button {
    margin-left: 10px;
  }
}
