.modal {
  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100000;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__box {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: fit-content;
    height: fit-content;

    background: #ffffff;
    box-shadow: 0px 4px 65px rgba(0, 0, 0, 0.08);
    border-radius: 30px;

    z-index: 101;
  }
}
