.checkbox {
  transition: 0.2s all ease-in-out;
  padding: 4px 8px;
  border-radius: 20px;
  margin: 0 2px;
}

.selected {
  color: white;
  background: #4a72ff !important;
  user-select: none;
}
