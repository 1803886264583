@import "src/assets/style/design.scss";

.approval-section {
  position: relative;
  padding: 52px 80px;
  box-sizing: border-box;
  max-width: 70vw;
  max-height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__title {
    color: #4a72ff;
    margin: 0;
    font-size: 30px;
  }

  &__subtitle {
    margin: 10px 0 15px 0;
    font-size: 23px;
    font-weight: 400;
  }

  &__button-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  &__approve-button,
  &__close-button {
    width: 150px;
    height: 50px;

    font-weight: 500;
  }

  &__close-icon {
    position: absolute;
    border: none;
    background: none;
    padding: 0;

    cursor: pointer;

    top: 30px;
    right: 30px;
    transition: 50ms ease-in transform;

    &:active {
      transform: scale(0.9);
    }

    img {
      display: block;
      pointer-events: none;
    }
  }
}
