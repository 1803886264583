.NotificationSuccess {
  min-height: 50px;
  padding: 8px 0;
  box-sizing: border-box;
  background: #272733;
  box-shadow: 0px 0px 2px rgba(147, 151, 173, 0.1), 0px 8px 28px rgba(147, 151, 173, 0.3);
  border-radius: 12px;
  padding-left: 10px;
  font-weight: 500;
  color: #ffffff;
}

.NotificationSuccess .icon {
  padding-bottom: 2px;
}

.NotificationError {
  min-height: 50px;
  background: #f74343;
  box-shadow: 0px 0px 2px rgba(255, 55, 55, 0.2), 0px 4px 12px rgba(255, 55, 55, 0.4);
  border-radius: 12px;
  padding-left: 10px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
}

.NotificationContent {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  min-height: 30px;
}

.NotificationContent .NotificationIcon {
  display: block;
  min-width: 20px;
  max-width: 20px;
  margin-right: 18px;
  font-size: 20px;
  height: 100%;
}
