.div {
  display: flex;
  align-items: center;
  top: 0px;
  height: 90px;
  position: sticky;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  background-color: white;
  flex-direction: column;
}

.red {
  color: red;
}