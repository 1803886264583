@import "src/assets/style/design.scss";

.level-blocks {
  display: grid;
  grid-template-columns: repeat(9, 64px);
  gap: 20px;
  margin-top: 20px;

  &__block {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 64px;
    height: 64px;

    border: none;
    border-radius: 12px;

    cursor: pointer;

    color: $baseColor;
    background: $lighterBlue;
    box-shadow: $shadow100;

    transition: background 0.2s ease-in-out, transform 50ms ease-in;

    &:hover {
      background: $lighterBlueHover;
    }

    &:active {
      transform: scale(0.98);
    }

    &_selected {
      background: $baseColor;
      color: #ffffff;

      &:hover {
        background: $baseColorHover;
      }
    }
  }
}
