.input {
  display: flex;
  color: hsl(0, 0%, 20%);
  font-size: 100%;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  outline: none;
  min-height: 38px;
  box-sizing: border-box;
  transition: all 100ms;
  padding: 2px 8px;
  max-width: 80px;
  width: 100%;
  margin-bottom: 5px;
  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 0 1px #2684ff;
    border-color: #2684ff;
  }
}
.input[disabled] {
  background-color: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 90%);
  border-radius: 4px;
  color: hsl(0, 0%, 60%);
  pointer-events: none;
}
