.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 38px;

  &:nth-child(1) {
    margin-bottom: 20px;
  }
}

.category {
  margin-right: 35px;
  font-weight: 500;
  font-size: 20px;
  color: hsl(0, 0%, 20%);
}

.children {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
