@import "./fonts";
@import "./normalize";
@import "./media";

.Toastify__toast-icon {
  display: none !important;
}

.Toastify {
  z-index: 1000000000;
}

.Toastify__close-button {
  color: white !important;
  opacity: 1 !important;
}

html {
  overflow-y: scroll;
}

body {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}
