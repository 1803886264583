@import "src/assets/style/design.scss";

.user-settings {
  overflow-y: scroll;
  position: relative;
  max-width: 70vw;
  max-height: 80vh;
  box-sizing: border-box;
  font-size: 22px;
  padding: 35px 55px;

  &__content-main-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__close-icon {
    position: absolute;
    border: none;
    background: none;
    padding: 0;

    cursor: pointer;

    top: 37px;
    right: 40px;
    transition: 50ms ease-in transform;

    &:active {
      transform: scale(0.9);
    }

    img {
      display: block;
      pointer-events: none;
    }
  }

  &__save-button {
    margin-top: 36px;
    padding: 16px 0;

    border-radius: 20px;
    text-align: center;
    font-weight: 700;
  }
}

.user-settings-table {
  display: flex;
  flex-direction: column;

  &__row {
    display: inline-flex;
    border-right: 2px solid rgba(74, 114, 255, 0.25);

    &_headline {
      font-weight: 400;
      color: $baseColor;
    }

    font-weight: 700;
  }

  &__cell-multi {
    display: flex;
    flex-direction: column;

    .user-settings-table__cell {
      height: 50%;
    }
  }
  &__cell {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    width: 160px;
    font-size: 1em;
    line-height: 1em;

    border-left: 2px solid $borderColor;
    border-top: 2px solid $borderColor;

    @media (max-width: 1600px) {
      width: 140px;
      padding: 12px 0;
    }
  }

  &_select-in-cells &__cell {
    padding: 12px 0;
  }
}

.user-settings-info {
  flex: 1;

  &__header {
    display: flex;
  }

  &__effmu {
    margin: 10px;
    margin-left: 0;
    font-size: 0.85em;
    line-height: 1.1em;
    color: #4a72ff;

    &-content {
      display: flex;
      gap: 20px;
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }

  &__color-pallete {
    width: max-content;
    margin-left: 0;
    margin-top: 18px;
  }

  &__header-alias {
    display: block;

    font-size: 1.33em;
  }

  &__header-password {
    display: flex;
    align-items: center;

    font-size: 0.9em;
    margin-top: 4px;
  }

  &__circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }

  &__color {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__header-password-block {
    display: flex;
    align-items: center;

    width: fit-content;
    height: 28px;
    margin-left: 6px;
    padding: 8px;

    border-radius: 5px;
    background-color: transparentize(#dddddd, 0.5);

    cursor: pointer;

    &:hover {
      background-color: transparentize(#dddddd, 0.3);
    }
  }

  &__header-password-text {
    display: block;

    &_hidden {
      line-height: 0;
      padding-top: 0.4em;
      user-select: none;
    }
  }

  &__header-password-img {
    display: block;
    height: 0.8em;
    margin-left: 4px;
    pointer-events: none;
    user-select: none;
  }

  &__settings {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  &__label {
    display: block;
    font-size: 0.9em;
    margin-bottom: 12px;
  }

  &__password-wrapper {
    margin-top: 16px;
  }

  &__times-wrapper {
    display: flex;
    flex-direction: column;

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 8px;

      input {
        width: 100%;
      }

      p {
        margin: 0;
        margin-bottom: 8px;
        font-size: 16px;
      }
    }

    input {
      flex: 1;
      background-color: white;
      border-radius: 4px;
      color: black;
      font-size: 16px;
      padding: 10px;
      border: none;
      outline: 1px solid black;
      border-color: #333333;
    }
  }

  &__email-wrapper {
    margin-top: 12px;
    flex: 1;
  }

  &__settings-wrapper {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 10px;
    margin-bottom: 4px;
  }

  &__input {
    height: 38px !important;

    &__text {
      font-size: 0.83em;
    }

    &_timezone,
    &_effmu {
      font-size: 16px;
    }
  }

  &__additional-info {
    font-size: 0.85em;
    line-height: 1.1em;
    color: rgba(0, 0, 0, 0.8);

    img {
      height: 1em;
      margin-right: 12px;
    }
  }

  &__additional-info-line {
    display: flex;
    align-items: center;
    margin-top: 14px;
  }
}

.time_info {
  margin-bottom: 0;
  font-size: 0.85em;
  padding-top: 10px;
}
