.elements-toggle {
  display: flex;
  width: 100%;

  &__button {
    height: 36px;
    flex: 1;
    margin-left: 16px;

    &:first-child {
      margin-left: 0;
    }
  }
}
