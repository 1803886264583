$baseColor: #4a72ff;

.offpeak-section {
  color: $baseColor;
  font-size: 20px;
  margin-top: 28px;

  &__wrapper {
    display: flex;
    flex-direction: column;

    div {
      width: auto;
      margin-right: 10px;
    }

    label {
      margin-left: 0;
    }
  }

  &__line {
    margin-top: 10px;
    display: flex;
  }

  &__title {
    margin-bottom: 20px;

    font-size: 1.2em;
  }

  &__input,
  &__button {
    font-size: 0.8em;
    height: 40px;
    width: 64px;
  }

  &__input {
    color: hsl(0, 0%, 20%);
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding-left: 8px;
  }
}
