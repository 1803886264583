@import "src/assets/style/design.scss";

.aliases-section {
  margin-top: 64px;

  font-size: 20px;
  color: $baseColor;

  &__title {
    margin: 0;

    font-size: 1.2em;
  }

  &__subtitle-wrapper {
    display: flex;
    align-items: center;

    margin-top: 20px;
  }

  &__subtitle {
    // чтобы поиск не прыгал
    min-width: 192px;
    margin: 0;
    font-size: 1.1em;
  }

  &__alias-form {
    margin-top: 20px;
    display: flex;
    max-width: 1200px;
    width: 100%;
    gap: 20px;

    &-input {
      font-size: 18px;

      width: auto;
      height: 40px;

      &_search {
        margin-left: 20px;
      }
    }

    &-select {
      span[aria-relevant="additions text"]+div {
        font-size: 18px;
        border: 0;
        background: #f5f8ff;

        border-radius: 12px;
        box-shadow: 0 0 0 1px #dde7ff;

        & * div {
          color: rgba(29%, 45%, 100%, 0.5);
        }

        & * div:not([id]) {
          color: black !important;
        }

        svg {
          color: rgba(29%, 45%, 100%, 0.5) !important;
        }
      }
    }

    &-button {
      padding: 0 40px;
    }
  }

  &__alias-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    max-width: 800px;
    margin-top: 24px;

    padding: 0 0 40px;
  }

  &__wrapper {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  &__search {
    margin-left: 30px;
    height: 40px;
    margin-bottom: 7px;
  }

  &__alias-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    min-width: 72px;
    font-weight: 500;

    border-radius: 12px;
    box-shadow: $shadow100;
    background-color: $lightBlue;

    cursor: pointer;
  }

  &__alias-item-text {
    padding: 8px 14px 8px 20px;
  }

  &__alias-item-delete-wrapper {
    height: 100%;
    padding: 8px;

    background-color: #fdfdfd;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    img {
      width: 12px;
      height: 12px;
    }

    &:hover {
      background-color: #f1f1f1;
    }
  }
}