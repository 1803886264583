@font-face {
  font-family: "YS Text";
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url(//yastatic.net/s3/home/fonts/ys/1/text-regular.woff2) format("woff2");
  src: url(//yastatic.net/s3/home/fonts/ys/1/text-regular.woff) format("woff");
}

@font-face {
  font-family: "YS Text";
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  src: url(//yastatic.net/s3/home/fonts/ys/1/text-medium.woff2) format("woff2");
  src: url(//yastatic.net/s3/home/fonts/ys/1/text-medium.woff) format("woff");
}

@font-face {
  font-family: "YS Text";
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url(//yastatic.net/s3/home/fonts/ys/1/text-bold.woff2) format("woff2");
  arc: url(//yastatic.net/s3/home/fonts/ys/1/text-bold.woff) format("woff");
}

body {
  font-family: "YS Text", "Helvetica Neue", Arial, sans-serif;
}
